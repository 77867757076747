<script>
  // Prop
  export let show = null;
  export let ref = 'heres_chat_panel';
  export let direction = 'right';
  export let chatUrl = '';
  export let style = '';

  // Computed
  let heresShow;
  $: heresShow =
    show === null
      ? 'heres_display_none'
      : show
      ? 'heres_visible'
      : 'heres_hidden';

  let heresDirection;
  $: heresDirection = 'heres_' + direction;

  let heresPanelClasses;
  $: heresPanelClasses = [heresShow, heresDirection].join(' ');
</script>

<div {ref} class={heresPanelClasses} {style} data-testid="ht-panel">
  <iframe
    title=""
    data-testid="ht-panel-iframe"
    id="heres_iframe"
    allow="geolocation; microphone; camera"
    frameborder="0"
    scrolling="no"
    src={chatUrl}
  />
</div>

<style>
  #heres_iframe {
    /*
    width: 100%;
    height: 100%;
    */
    background-color: #eceff6;
    background-size: 150px;
    background-image: url(https://raw.githubusercontent.com/SamHerbert/SVG-Loaders/master/svg-loaders/oval.svg);
    background-position: center;
    background-repeat: no-repeat;

    /*
      Bug fixing ios
    */
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100px;
    min-width: 100%;
    height: 100px;
    min-height: 100%;
  }
</style>
