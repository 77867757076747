<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  // Prop
  export let BubbleComponent;
  export let ActionComponent;
  export let PanelComponent;
  export let config;
  export let showAction;
  export let showBubble;
  export let showPanel;
  export let chatUrl;
  export let bubble;
  export let counterNotifications;

  // Data

  // Computed

  let direction;
  $: direction = config.directionLeft ? 'left' : 'right';

  function onBubbleQuickReply(event) {
    dispatch('bubblequickreply', event.detail);
  }

  console.log('config', config);
</script>

<div>
  <svelte:component
    this={ActionComponent}
    ref="heres_action"
    bind:show={showAction}
    bind:showPanel
    bind:counterNotifications
    {direction}
  >
    <div slot="action_open">
      <img src={config.widgetAvatar} alt="" class="heres_action_image" />
    </div>

    <div slot="action_close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height="45px"
        width="45px"
        fill={config.colors.textColorAction}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
      </svg>
    </div>
  </svelte:component>

  <svelte:component
    this={BubbleComponent}
    ref="heres_bubble"
    {direction}
    bind:show={showBubble}
    style={`background-color:${config.bgColorBubble};`}
    {bubble}
    on:click-quick-reply={onBubbleQuickReply}
  />

  <svelte:component
    this={PanelComponent}
    ref="heres_chat_panel"
    show={showPanel}
    {chatUrl}
    {direction}
  />
</div>

<style>
  [slot='action_open'],
  [slot='action_close'] {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  :global(.heres_display_none) {
    display: none;
  }

  :global(.heres_visible) {
    opacity: 1;
  }

  :global(.heres_hidden) {
    opacity: 0;
    z-index: 1 !important;
  }

  /*************************************
   ********** Heres chat panel  ********
   *************************************/
  :global([ref='heres_chat_panel']) {
    position: fixed;
    bottom: 120px;
    min-width: 350px;
    width: var(--panel-size-width);

    border: 0px;
    z-index: 2147483630; /* max-value: 2147483647 */

    height: var(--panel-size-height);
    max-height: calc(100vh - 150px);
    min-height: 400px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  :global([ref='heres_chat_panel'].heres_right) {
    right: 20px;
  }

  :global([ref='heres_chat_panel'].heres_left) {
    left: 20px;
  }

  /*************************************
   ********** Heres action  ************
   *************************************/
  :global([ref='heres_action']) {
    position: fixed;
    bottom: 20px;
    background-color: var(--bg-color-action);
    cursor: pointer;
    border-radius: 50%;
    width: var(--size-action-desktop);
    height: var(--size-action-desktop);
    z-index: 2147483610;
  }

  .heres_action_image {
    background-color: var(--bg-color-action);
    cursor: pointer;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  :global([ref='heres_action'].heres_right) {
    right: 20px;
  }

  :global([ref='heres_action'].heres_left) {
    left: 20px;
  }

  /*************************************
   ********** Heres bubble  ************
   *************************************/
  :global([ref='heres_bubble']) {
    position: fixed;
    background-color: var(--bg-color-bubble);
    color: var(--text-color-bubble);
    bottom: calc(5px + var(--size-action-desktop) + 20px);
    max-width: 350px;
    border-radius: 10px 10px 10px 10px;
    padding: 7px 14px 7px 14px;
    z-index: 2147483610;
  }

  :global([ref='heres_bubble'].heres_right) {
    right: calc(20px + (var(--size-action-desktop) / 2));
    border-bottom-right-radius: 0px;
  }

  :global([ref='heres_bubble'].heres_left) {
    left: calc(20px + (var(--size-action-desktop) / 2));
    border-bottom-left-radius: 0px;
  }

  /**
  * Mobile Phones Portrait or Landscape
  * iPhone 4+ Portrait or Landscape
  * Tablets Portrait or Landscape
  */
  @media screen and (max-width: 1024px),
    screen and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    :global([ref='heres_action']) {
      width: var(--size-action-mobile);
      height: var(--size-action-mobile);
      bottom: 10px;
    }

    :global([ref='heres_chat_panel']) {
      max-height: unset;
      height: 100%;
      bottom: 0px;
      right: 0px !important;
      width: 100vw;
    }

    :global([ref='heres_chat_panel'].heres_right) {
      right: 0px;
    }

    :global([ref='heres_chat_panel'].heres_left) {
      left: 0px;
    }

    :global([ref='heres_action'].heres_right) {
      right: 12px;
    }

    :global([ref='heres_action'].heres_left) {
      left: 12px;
    }

    :global([ref='heres_bubble']) {
      bottom: calc(var(--size-action-mobile) + 15px);
    }

    :global([ref='heres_bubble'].heres_right) {
      right: calc(12px + (var(--size-action-mobile) / 2));
    }

    :global([ref='heres_bubble'].heres_left) {
      left: calc(12px + (var(--size-action-mobile) / 2));
    }
  }

  :global(.bubbleQuickReply:last-of-type) {
    margin-bottom: 6px;
  }

  :global(.bubbleQuickReply, .bubbleQuickReply:focus, .bubbleQuickReply:active) {
    white-space: initial;
    font-size: 14px;
    background-color: var(--bg-color-bubble);
    color: var(--text-color-bubble) !important;
    border-radius: 15px;
    border: solid 2px var(--text-color-bubble);
    font-weight: bold;
    margin-top: 6px;
    padding: 3px 3px 3px 3px;
    width: 100%;
  }

  :global(.bubbleQuickReply:hover) {
    color: var(--bg-color-bubble) !important;
    background-color: var(--text-color-bubble);
    border: solid 2px #fff;
  }
</style>
