export class Logger {
  constructor({ debug = true } = {}) {
    this.prefix = '[Heres-widget]';
    this.debug = debug;
  }

  buildArgs(args) {
    if (typeof args[0] === 'string') {
      args[0] = `${this.prefix} ${args[0]}`;
    } else {
      args.unshift(this.prefix);
    }
    return args;
  }

  log(...args) {
    if (!this.debug) {
      return;
    }
    const _args = this.buildArgs(args);
    console.log(..._args);
  }
}

// function test () {
//   const logger = new Logger()
//   logger.log('Ciao', { x: true })
//   logger.log({ y: false })
// }

// test()
