import Engine from 'store/src/store-engine';
import cookieStorage from 'store/storages/cookieStorage';
import localStorage from 'store/storages/localStorage';
import defaults from 'store/plugins/defaults';
import expire from 'store/plugins/expire';

export class Store {
  constructor(type = 'cookie') {
    const storages = [];
    if (type === 'cookie') {
      storages.push(cookieStorage);
    } else {
      storages.push(localStorage);
    }
    const plugins = [defaults, expire];
    this.__store = Engine.createStore(storages, plugins);
  }

  write(key, value, duration = null) {
    if (duration) {
      this.__store.set(key, value, new Date().getTime() + duration);
    } else {
      this.__store.set(key, value);
    }
  }

  read(key) {
    return this.__store.get(key);
  }
}
