<svelte:options accessors />

<script>
  import { onMount } from 'svelte';
  import { Store, Triggers, Chat, Logger, Url } from './libs/libs.module.js';

  // DEV
  const CHAT_LOCAL = false;

  // Data
  let store = null;
  const triggers = new Triggers();
  let counterNotifications = 0;
  const DEVICE = isMobile() ? 'mobile' : 'desktop';
  const chat = new Chat({ local: CHAT_LOCAL });

  /**
   * Widget settings
   */
  const WIDGET_SETTINGS = {
    mobileBotImage: '',
    autoOpen: false,
    openDelay: 0,
    bubble: {
      message: '',
      quickReplies: [],
      openDelay: 0,
      toShow: false,
    },
    directionLeft: false,
    chatUrl: 'about:blank',
    closeImage: '',
    sizeActionDesktop: '85px',
    sizeActionMobile: '50px',
    widgetAvatar: '',
    colors: {
      bgColorAction: '',
      textColorAction: '',
      bgColorBubble: '',
      textColorBubble: '',
    },
    panel: {
      size: {
        width: '',
        height: '',
      },
    },
    channel: 'heres',
  };

  // Prop
  export let Components;
  export let config;
  export let showPanel = null;
  export let showAction = null;
  export let showBubble = null;
  export let logger = new Logger();

  /**
   * @param {Object} session
   * @param {String} session.userId
   * @param {String} session.integrity
   * @param {Object} session.data
   */
  export let session = {};
  export let code = '';
  export let params = {};
  export let channel = null;

  let onMountPhase = true;

  /**
   * Reattività generale.
   */
  $: {
    if (!onMountPhase) {
      chat
        .updateArgs({ session, step: code, fromUrl: $Url.href }, store)
        .then(_ => {
          reactivityWidgetSettings();
          initChatUrl();
        });

      if (store) {
        if (showPanel === true) {
          hideBubble();
          store.write('heres_chat_open', 1);
        } else if (showPanel === false) {
          store.write('heres_chat_open', 0);
        }
      }
    }
  }

  onMount(async () => {
    onMountPhase = true;

    if (config.enableRoutes) {
      triggers.setup(config.triggers);
    } else {
      console.warn('Triggers disabled.');
    }

    chat.setup({ agent: config.customer, hiveUrl: config.hiveUrl });
    store = new Store(config.storage);

    // Init chat args
    await chat.updateArgs({ session, step: code }, store);

    initWidgetSettings();

    const CURRENT_URL = window.location.href;
    // const CURRENT_URL = 'https://widget.hereschat.it/testing/demo.html?agent=zoe-uat' // TEST DEV
    applyTrigger(CURRENT_URL);

    initChatUrl();

    if (WIDGET_SETTINGS.bubble.toShow && WIDGET_SETTINGS.bubble.openDelay) {
      setTimeout(fShowBubble, WIDGET_SETTINGS.bubble.openDelay * 1000);
    }

    if (store.read('heres_chat_open') && DEVICE === 'desktop') {
      open();
    } else if (WIDGET_SETTINGS.autoOpen) {
      if (WIDGET_SETTINGS.openDelay) {
        // Open chat after tot seconds...
        setTimeout(open, WIDGET_SETTINGS.openDelay * 1000);
      } else {
        open();
      }
    }

    onMountPhase = false;

    return () => {
      //do something on destroy
    };
  });

  function applyTrigger(url) {
    const trigger = triggers.findByUrl(url);
    if (trigger) {
      logger.log('Trigger', trigger);
      updateWidgetSettingsAndChatArgsWithTrigger(trigger);
    }
  }

  function initWidgetSettings() {
    if (config) {
      WIDGET_SETTINGS.sizeActionDesktop =
        config.sizeActionDesktop || WIDGET_SETTINGS.sizeActionDesktop;
      WIDGET_SETTINGS.sizeActionMobile =
        config.sizeActionMobile || WIDGET_SETTINGS.sizeActionMobile;
      const bubbleToShow = isMobile() ? !config.bubble.hideOnMobile : true;
      setBubble({ ...config.bubble, toShow: bubbleToShow });
      WIDGET_SETTINGS.colors.bgColorBubble =
        config.colors && config.colors.bgColorHeader;
      WIDGET_SETTINGS.colors.textColorBubble =
        config.colors && config.colors.textColorHeader;
      WIDGET_SETTINGS.colors.bgColorAction =
        config.colors && config.colors.bgColorHeader;
      WIDGET_SETTINGS.colors.textColorAction =
        config.colors && config.colors.textColorHeader;
      WIDGET_SETTINGS.widgetAvatar = config.widget && config.widget.avatar;
      WIDGET_SETTINGS.closeImage = config.closeImage;
      WIDGET_SETTINGS.directionLeft = config.directionLeft;
      WIDGET_SETTINGS.channel =
        channel || (config.widget && config.widget.channel) || 'heres';

      try {
        WIDGET_SETTINGS.panel.size.width = config.widget.panel.size.width;
      } catch (error) {
        WIDGET_SETTINGS.panel.size.width = '400px';
      }

      try {
        WIDGET_SETTINGS.panel.size.height = config.widget.panel.size.height;
      } catch (error) {
        WIDGET_SETTINGS.panel.size.height = '600px';
      }
    }
    if (typeof params.autoOpen === 'boolean') {
      WIDGET_SETTINGS.autoOpen = params.autoOpen;
    }
    if (typeof params.openDelay === 'number') {
      WIDGET_SETTINGS.openDelay = params.openDelay;
    }
  }

  function reactivityWidgetSettings() {
    if (typeof params.autoOpen === 'boolean') {
      WIDGET_SETTINGS.autoOpen = params.autoOpen;
    }
    if (typeof params.openDelay === 'number') {
      WIDGET_SETTINGS.openDelay = params.openDelay;
    }
  }

  /**
   * Updates WIDGET_SETTINGS and chat.args with the options of trigger.
   * @param {Object} trigger
   * @ignore
   */
  function updateWidgetSettingsAndChatArgsWithTrigger(trigger) {
    if (!trigger) {
      return;
    }

    let open = false;
    try {
      open = trigger.openOn.includes(DEVICE);
    } catch (error) {}
    WIDGET_SETTINGS.autoOpen = WIDGET_SETTINGS.autoOpen || open;
    WIDGET_SETTINGS.openDelay = trigger.delayOpen;
    chat.args.triggerId = trigger.id;
    if (trigger.step) {
      chat.args.step = trigger.step;
    }
    if (trigger.bubble.message && trigger.engageOn.includes(DEVICE)) {
      store.write('heres_bubble', false);
      setBubble(trigger.bubble);
    }
  }

  /**
   * Set welcome or trigger bubble.
   * @param {Object} bubble
   * @param {String} bubble.message message
   * @param {Array} bubble.quickReplies quick replies
   * @param {Number} bubble.openDelay seconds delay before to show bubble
   * @param {Boolean} bubble.toShow if false the bubble won't show
   */
  export function setBubble(bubble) {
    if (!bubble || !bubble.message) {
      return;
    }
    const toShow = typeof bubble.toShow === 'boolean' ? bubble.toShow : true;
    WIDGET_SETTINGS.bubble = {
      message: bubble.message,
      quickReplies: bubble.quickReplies || [],
      openDelay: bubble.openDelay || 1,
      toShow,
    };
  }

  function isMobile() {
    const __isMobile = a => {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      );
    };
    return __isMobile(navigator.userAgent || navigator.vendor || window.opera);
  }

  function initChatUrl() {
    if (!showPanel) {
      return;
    }
    WIDGET_SETTINGS.chatUrl = chat.getUrl(WIDGET_SETTINGS.channel);
    logger.log('Init chat url ', WIDGET_SETTINGS.chatUrl);
  }

  export function open() {
    hideBubble();
    showAction = true;
    showPanel = true;
  }

  export function close() {
    if (showPanel !== null) {
      showPanel = false;
    }
  }

  /**
   * Show bubble. You can use bubble argument to set bubble.
   * @param {Bubble} bubble
   * @param {Number} seconds
   * @example
   * heres('showBubble')
   * heres('showBubble', args)
   */
  export function fShowBubble({ bubble = null, seconds = 0 } = {}) {
    setBubble(bubble);
    let cookieBubble = false;
    try {
      cookieBubble = store.read('heres_bubble');
    } catch (e) {
      cookieBubble = false;
    }
    if ((showPanel || cookieBubble) && (!bubble || !bubble.message)) {
      return;
    }
    showBubble = true;
    showAction = true;
    try {
      store.write('heres_bubble', true);
    } catch (error) {
      console.warn('[Heres-widget] Store not available');
    }
  }

  export function hideBubble() {
    if (showBubble !== null) {
      showBubble = false;
    }
  }

  /**
   * Handler quick reply click.
   * @param {Element} e
   * @ignore
   */
  function onBubbleQuickReply(e) {
    const quickReply = e.detail;
    code = quickReply.step;
    chat.args.triggerId = null;
    open();
  }

  export function incNotifications() {
    if (showPanel) {
      return;
    }
    counterNotifications += 1;
  }

  /**
   * Activate trigger using url or id.
   * @param {Object} obj
   * @param {Number} obj.id
   * @param {String} obj.url
   */
  export function activateTrigger(obj) {
    const trigger =
      (obj.url && triggers.findByUrl(obj.url)) ||
      (obj.id && triggers.findById(obj.id));

    if (!trigger) {
      throw new Error("There isn't trigger for this criteria");
    }

    updateWidgetSettingsAndChatArgsWithTrigger(trigger);

    if (WIDGET_SETTINGS.bubble.openDelay) {
      setTimeout(fShowBubble, WIDGET_SETTINGS.bubble.openDelay * 1000);
    }

    if (WIDGET_SETTINGS.autoOpen) {
      if (WIDGET_SETTINGS.openDelay) {
        // Open chat after tot seconds...
        setTimeout(open, WIDGET_SETTINGS.openDelay * 1000);
      } else {
        open();
      }
    }

    chat.sendData({
      event: 'trigger',
      data: trigger,
    });
  }

  export function setItem(key, value) {
    /**
     * @todo Aggiungere validatore per ogni chiave.
     */
    const validator = {
      // Colors
      'colors.bgColorHeader': value => true, // mustBeColor(value)
      'colors.textColorHeader': value => true,
      'colors.bgColorBody': value => true,
      'colors.bgColorBotMsg': value => true,
      'colors.bgColorUserMsg': value => true,
      'colors.textColorUserMsg': value => true,
      'colors.textColorBotMsg': value => true,
      'colors.iconColorFooter': value => true,
      'colors.bgColorFooter': value => true,
      'colors.bgColorOperatorMsg': value => true,
      'colors.textColorOperatorMsg': value => true,

      // Header
      'header.title': value => true,
      'header.subtitle': value => true,
      'header.avatar.html': value => true,
      'header.avatar.image': value => true,
      'header.animation.active': value => true,
      'header.animation.avatar.html': value => true,
      'header.animation.avatar.image': value => true,
      'header.animation.title': value => true,
      'header.animation.subtitle': value => true,
      'header.menu': value => true,

      // Body
      'body.avatars.operator.image': value => true,
      'body.avatars.operator.show': value => true,
      'body.avatars.bot.show': value => true,
      'body.avatars.bot.image': value => true,

      // Widget
      'widget.avatar': value => true,
      'widget.panel.size.width': value => true,
      'widget.panel.size.height': value => true,
    };
    if (!validator[key]) {
      console.warn(`[Heres-widget] Key ${key} not allowed.`);
      return;
    }

    if (!validator[key](value)) {
      console.error(`[Heres-widget] Value ${value} must be type ...`);
      return;
    }

    if (
      ['header.', 'colors.', 'body.'].some(prefix => key.startsWith(prefix))
    ) {
      if (key === 'colors.bgColorHeader') {
        WIDGET_SETTINGS.colors.bgColorAction = value;
        WIDGET_SETTINGS.colors.bgColorBubble = value;
      } else if (key === 'colors.textColorHeader') {
        WIDGET_SETTINGS.colors.textColorAction = value;
      }

      chat.sendData({
        event: 'setItem',
        data: { key, value },
      });
      return;
    }

    if (key === 'widget.panel.size.width') {
      WIDGET_SETTINGS.panel.size.width = value;
    } else if (key === 'widget.panel.size.height') {
      WIDGET_SETTINGS.panel.size.height = value;
    } else if (key === 'widget.avatar') {
      WIDGET_SETTINGS.widgetAvatar = value;
    }
  }

  /**
   * Send message
   * @param {String|Object} msg message
   */
  export function sendMsg(msg) {
    let message;
    if (typeof msg === 'object') {
      if (typeof msg.payload !== 'string' || typeof msg.label !== 'string') {
        throw new Error('Payload or label must be a string');
      }
      if (!msg.payload || !msg.label) {
        throw new Error('Message must have payload and label');
      }
      const payload = msg.payload.replace(/:/g, '');
      const label = msg.label.replace(/:/g, '');
      message = `##${payload}:${label}`;
    } else if (typeof msg === 'string') {
      if (!msg.trim()) {
        throw new Error('Message cannot be empty');
      }
      message = msg.trim();
    }

    const data = {
      type: 'text',
      value: message,
    };

    chat.sendData({
      event: 'sendMsg',
      data,
    });
  }
</script>

<!-- Powered by Heres -->
<div
  data-testid="ht-app"
  style="
    --bg-color-action: {WIDGET_SETTINGS.colors.bgColorAction};
    --text-color-action: {WIDGET_SETTINGS.colors.textColorAction};
    --size-action-desktop: {WIDGET_SETTINGS.sizeActionDesktop};
    --size-action-mobile: {WIDGET_SETTINGS.sizeActionMobile};
    --bg-color-bubble: {WIDGET_SETTINGS.colors.bgColorBubble};
    --text-color-bubble: {WIDGET_SETTINGS.colors.textColorBubble};
    --panel-size-width: {WIDGET_SETTINGS.panel.size.width};
    --panel-size-height: {WIDGET_SETTINGS.panel.size.height};
  "
>
  <svelte:component
    this={Components.WidgetComponent}
    BubbleComponent={Components.BubbleComponent}
    ActionComponent={Components.ActionComponent}
    PanelComponent={Components.PanelComponent}
    config={WIDGET_SETTINGS}
    chatUrl={WIDGET_SETTINGS.chatUrl}
    bind:showAction
    bind:showPanel
    bind:showBubble
    bind:counterNotifications
    bubble={WIDGET_SETTINGS.bubble}
    on:bubblequickreply={onBubbleQuickReply}
  />
</div>

<style>
  /*************************************
   ********** Animations  ************
   *************************************/
  :global([ref='heres_chat_panel'].heres_right.heres_hidden, [ref='heres_action'].heres_right.heres_hidden, [ref='heres_bubble'].heres_right.heres_hidden) {
    animation: slide-out-down 0.9s cubic-bezier(0.11, 0, 0.5, 0) both;
    /* animation: slide-out-right 0.5s cubic-bezier(0.600, -0.280, 0.735, 0.045) both; */
  }

  :global([ref='heres_chat_panel'].heres_right.heres_visible, [ref='heres_action'].heres_right.heres_visible, [ref='heres_bubble'].heres_right.heres_visible) {
    animation: slide-in-down 0.9s cubic-bezier(0.5, 1, 0.89, 1) both;
    /* animation: slide-in-right 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both; */
  }

  :global([ref='heres_chat_panel'].heres_left.heres_hidden, [ref='heres_action'].heres_left.heres_hidden, [ref='heres_bubble'].heres_left.heres_hidden) {
    animation: slide-out-down 0.9s cubic-bezier(0.11, 0, 0.5, 0) both;
    /* animation: slide-out-left 0.5s cubic-bezier(0.600, -0.280, 0.735, 0.045) both; */
  }

  :global([ref='heres_chat_panel'].heres_left.heres_visible, [ref='heres_action'].heres_left.heres_visible, [ref='heres_bubble'].heres_left.heres_visible) {
    animation: slide-in-down 0.9s cubic-bezier(0.5, 1, 0.89, 1) both;
    /* animation: slide-in-left 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both; */
  }

  /*****************************************
   ********** Animations timelines  ********
   *****************************************/
  @keyframes slide-in-down {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-out-down {
    0% {
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      transform: translateY(1000px);
      opacity: 0;
    }
  }

  @keyframes slide-out-left {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(-1000px);
      opacity: 0;
    }
  }

  @keyframes slide-in-left {
    0% {
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(1000px);
      opacity: 0;
    }
  }

  @keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
</style>
