export class Triggers {
  constructor(triggers = []) {
    this.setup(triggers);
  }

  setup(triggers) {
    this.triggers = triggers;
  }

  findById(id) {
    const trigger = this.triggers.find(trigger => trigger.id === id);
    return trigger;
  }

  findByUrl(url) {
    const trigger = this.triggers.find(trigger =>
      Triggers.testUrl(trigger, url)
    );
    return trigger;
  }

  static testDatetimeRange(trigger, now = new Date()) {
    const startDate = trigger.start
      ? new Date(trigger.start)
      : new Date('12/31/1000');
    const endDate = trigger.end
      ? new Date(trigger.end)
      : new Date('12/30/9999');
    endDate.setDate(endDate.getDate() + 1);
    return (
      now >= startDate &&
      now <= endDate &&
      now.getHours() >= Number(trigger.startTime.HH) &&
      now.getMinutes() >= Number(trigger.startTime.mm) &&
      now.getHours() <= Number(trigger.endTime.HH) &&
      now.getMinutes() <= Number(trigger.endTime.mm)
    );
  }

  static testUrl(trigger, url) {
    const regexp = new RegExp(trigger.regexp, 'g');
    return regexp.test(url) && Triggers.testDatetimeRange(trigger);
  }
}
