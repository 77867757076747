<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  // Prop
  export let show = null;
  export let ref = 'heres_bubble';
  export let direction = 'right';
  export let style;
  export let bubble = {};

  // Computed
  let heresShow;
  $: heresShow =
    show === null
      ? 'heres_display_none'
      : show === true
      ? 'heres_visible'
      : 'heres_hidden';

  let heresDirection;
  $: heresDirection = 'heres_' + direction;

  let heresBubbleClasses;
  $: heresBubbleClasses = [heresShow, heresDirection].join(' ');

  // Methods
  function onClose() {
    show = false;
  }

  function onBubbleQuickReply(e) {
    dispatch('click-quick-reply', e.target.dataset);
  }
</script>

<div {ref} class={heresBubbleClasses} {style} data-testid="ht-bubble">
  <img
    src="https://config.hereschat.it/common/closeChat.png"
    id="heres_bubble_close"
    class={heresDirection}
    alt=""
    on:click={onClose}
  />
  {@html bubble.message}
  {#each bubble.quickReplies as quickReply}
    {#if quickReply.step && quickReply.label}
      <br />
      <button
        type="button"
        data-step={quickReply.step}
        data-label={quickReply.label}
        class="bubbleQuickReply"
        style="white-space: initial;"
        on:click={onBubbleQuickReply}
      >
        {quickReply.label}
      </button>
    {/if}
  {/each}
</div>

<style>
  #heres_bubble_close {
    background-color: var(--bg-color-bubble);
    border: 1px solid white;
    position: fixed;
    z-index: 9;
    top: -8px;
    border-radius: 20px;
    width: 23px;
    height: 23px;
  }

  #heres_bubble_close.heres_right {
    left: -9px;
    right: unset;
  }

  #heres_bubble_close.heres_left {
    right: -9px;
    left: unset;
  }
</style>
