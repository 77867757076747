import App from './App.svelte';
import { Logger } from './libs/libs.module.js';
import { HeresWidget, EasycoopWidget } from './widgets/widgets.module.js';
import {
  BubbleComponent,
  ActionComponent,
  PanelComponent,
} from './components/components.module.js';

/**
 * Tutti i metodi elencati sono accessibili attraverso il namespace heres.
 * @author Stefano Giurgiano
 */
class WidgetChatBot {
  /**
   * @param {String} agent
   * @hideconstructor
   */
  constructor(agent) {
    this.logger = new Logger();
    this.agent = agent;
    this.config = null;
    this.app = null;
    this.eventsFn = {};
  }

  get supportedAPI() {
    return [
      'init',
      'open',
      'close',
      'show',
      'hide',
      'showBubble',
      'hideBubble',
      'destroy',
      'onEvents',
      'offEvents',
      'setItem',
      'activateTrigger',
      'sendMsg',
      'onClose',
      'onOpen',
    ];
  }

  /**
   * Initialize the chatbot widget.
   * You can pass argument throught a cookie called "heres-init".
   * Wheter you want pass only arguments.userId you can use the "heres-id" cookie
   * or creating the "heres-init" cookie structured as {userId: 'user-1234'}.
   * @param {Object} [arguments]
   * @param {String} [arguments.userId] external id of user.
   * @param {String} [arguments.integrity] sha1(arguments.userId + pepper) where pepper is a arranged secret string with Heres
   * @param {String} [arguments.code=null] code of step to start.
   * @param {Object} [arguments.params] settings of chat.
   * @param {String} [arguments.params.selector="body"] selector where place the widget.
   * @param {Boolean} [arguments.params.autoOpen=false] chat starts open.
   * @param {Number} [arguments.params.openDelay] wait n seconds before opening the widget.
   * @param {Object} [arguments.data] data to send to bot (cannot use it on zendesk integration).
   * @param {String} [arguments.zendeskDisplayName] the name visualized on zendesk chat (only zendesk use).
   * @param {String} [arguments.channel] used for dev mode.
   * @example
   * await heres('init', arguments)
   * await heres('init', {userId: 'user-id-1234'})
   * await heres('init') // With setted cookie "heres-init" or "heres-id"
   * arguments.integrity = crypto.createHash('sha1').update(arguments.userId + 'secret_string_124', 'binary').digest('hex') // NodeJS example
   */
  async init({
    userId = '',
    integrity = '',
    code = null,
    platform = 'web',
    params = {},
    data = {},
    zendeskDisplayName = null,
    channel = null,
  } = {}) {
    this.logger.log('Init', arguments[0]);

    if (zendeskDisplayName) {
      data.displayName = zendeskDisplayName;
    }

    const session = {
      userId,
      integrity,
      data,
      platform,
    };
    if (this.app) {
      if (this.app.code !== code) {
        this.app.code = code;
      }
      this.app.session = session;
      this.app.params = params;
      return this.app;
    }

    if (!this.config) {
      this.config = await this.getConfig();
    }

    this.app = new App({
      target: document.body,
      props: {
        Components: {
          WidgetComponent: this.agent.startsWith('easycoop-cs')
            ? EasycoopWidget
            : HeresWidget,
          BubbleComponent,
          ActionComponent,
          PanelComponent,
        },
        config: this.config,
        showAction: true,
        showPanel: null,
        showBubble: null,
        code,
        params,
        session,
        channel,
        logger: this.logger,
      },
      intro: true,
    });
    this.addEventListenerMessage();
    return this.app;
  }

  async getConfig() {
    const response = await window.fetch(
      `https://config.hereschat.it/${this.agent}/config.json`
    );
    const config = await response.json();
    return config;
  }

  /**
   * Close widget.
   * @example
   * heres('close')
   */
  close() {
    this.app.close();
  }

  /**
   * Open widget.
   * @example
   * heres('open')
   */
  open() {
    this.app.open();
  }

  /**
   * Destroy widget chat bot.
   * @example
   * heres('destroy')
   */
  destroy() {
    this.hide();
    this.app.$destroy();
    this.app = null;
    return this.app;
  }

  /**
   * Show widget.
   * @example
   * heres('show')
   */
  show() {
    this.app.$set({ showAction: true });
  }

  /**
   * Hide widget.
   * @example
   * heres('hide')
   */
  hide() {
    if (this.app.showAction !== null) {
      this.app.$set({ showAction: false });
    }
    if (this.app.showBubble !== null) {
      this.app.$set({ showBubble: false });
    }
    if (this.app.showPanel !== null) {
      this.app.$set({ showPanel: false });
    }
  }

  /**
   * Show bubble. You can use bubble argument to set bubble.
   * @param {Object} bubble
   * @param {String} bubble.message
   * @param {Array} bubble.quickReplies
   * @param {String} bubble.quickReplies[].label
   * @param {String} bubble.quickReplies[].payload
   * @param {Number} seconds
   * @example
   * heres('showBubble')
   * heres('showBubble', args)
   */
  showBubble({ bubble = null, seconds = 0 } = {}) {
    this.app.fShowBubble({ bubble, seconds });
  }

  /**
   * Hide bubble.
   * @example
   * heres('hideBubble')
   */
  hideBubble() {
    if (this.app.showBubble !== null) {
      this.app.$set({ showBubble: false });
    }
  }

  addEventListenerMessage() {
    window.addEventListener('message', messageEvent => {
      const { event, data, system } = messageEvent.data;
      if (system) {
        this.logger.log(
          'onEvents receveid system event',
          event,
          ' with data ',
          data
        );
        if (event === 'INC-NOTIFICATIONS') {
          this.app.incNotifications();
        } else if (event === 'CLOSE') {
          this.close();
          if (this.eventsFn && this.eventsFn.__close__) {
            this.eventsFn.__close__();
          }
        }
      } else {
        this.logger.log('onEvents receveid event ', event, ' with data ', data);
        if (this.eventsFn && this.eventsFn[event]) {
          this.eventsFn[event](data);
        }
      }
      // can message back using event.source.postMessage(...)
    });
  }

  /**
   * Event trigged when chat is closed.
   * @param {Function} eventFn Function called when the event is trigged
   * @example
   * heres('onClose', function () { console.log('...') })
   */
  onClose(eventFn) {
    this.eventsFn.__close__ = eventFn;
    this.logger.log('onClose', this.eventsFn.__close__);
  }

  /**
   * Event trigged when chat is opened.
   * @param {Function} eventFn Function called when the event is trigged
   * @example
   * heres('onOpen', function () { console.log('...') })
   */
  onOpen(eventFn) {
    this.eventsFn.__open__ = eventFn;
    this.logger.log('onOpen', this.eventsFn.__open__);
  }

  /**
   * Attaches one or more event handlers
   * @param {Object} eventsFn It's a object where key is the event's name and value is a function that will called when event is triggered.
   * @example
   * heres('onEvents', { myEvent: function (data) { console.log('myEvent with data', data)} })
   */
  onEvents(eventsFn) {
    this.eventsFn = { ...this.eventsFn, ...eventsFn };
    this.logger.log('onEvents', this.eventsFn);
  }

  /**
   * Used to remove all events handlers attached
   * @example
   * heres('offEvents')
   */
  offEvents() {
    delete this.eventsFn;
    this.eventsFn = {};
  }

  setItem({ key, value }) {
    this.app.setItem(key, value);
  }

  /**
   * Activate trigger using url or id.
   * @example
   * Activete trigger by url
   * heres('activateTrigger', {url: 'http....my_url'})
   *
   * Activate trigger by id
   * heres('activateTrigger', {id: 3})
   * @param {Object} trigger
   * @param {Number} trigger.id
   * @param {String} trigger.url
   */
  activateTrigger({ url = null, id = null }) {
    this.app.activateTrigger({ url, id });
  }

  /**
   * Send message
   * @param {String|Object} msg message can be a string or object with payload and label
   * @param {String} msg.payload payload
   * @param {String} msg.label label to show in chat.
   */
  sendMsg(msg) {
    this.app.sendMsg(msg);
  }
}

const widgetChatBot = new WidgetChatBot();

/**
 * Method that handles all API calls
 * @ignore
 */
function apiHandler(api, params) {
  if (!api) {
    throw Error('API method required');
  }
  if (!widgetChatBot.supportedAPI.includes(api)) {
    throw Error(`Method ${api} is not supported`);
  }
  if (widgetChatBot[api]) {
    return widgetChatBot[api](params);
  } else {
    console.warn(`No handler defined for ${api}`);
  }
}

/**
 * The main entry of the application
 * @ignore
 */
async function main(window) {
  widgetChatBot.agent = window.heresAgent;
  widgetChatBot.logger.debug = window.heresDebug || false;
  // Empty queue
  let globalObject = window[window['heres-widget']];
  const queue = globalObject.q;
  if (queue) {
    for (let i = 0; i < queue.length; i++) {
      const [api, params] = queue[i];
      if (api === 'init') {
        await apiHandler(api, params);
      } else {
        apiHandler(api, params);
      }
    }
  }
  globalObject = apiHandler;
  window[window['heres-widget']] = apiHandler;
}

main(window);
