<script>
  // Prop
  export let show = true;
  export let showPanel = false;
  export let ref = 'heres_action';
  export let direction = 'right';
  export let style = '';
  export let counterNotifications = 0;

  // Data

  // Computed
  let heresShow;
  $: heresShow = show ? 'heres_visible' : 'heres_hidden';

  let heresDirection;
  $: heresDirection = 'heres_' + direction;

  let heresActionClasses;
  $: heresActionClasses = [heresShow, heresDirection].join(' ');

  // Methods
  function onAction() {
    showPanel = !showPanel;
    window.postMessage({ event: showPanel ? '__open__' : '__close__' }, '*');
    if (showPanel) {
      counterNotifications = 0;
    }
  }
</script>

<div
  data-testid="ht-action"
  on:click={onAction}
  {ref}
  class={heresActionClasses}
  {style}
>
  <div
    class={[
      'heres_slot_action',
      !showPanel ? 'heres_visible' : 'heres_display_none',
    ].join(' ')}
  >
    <slot name="action_open" />
  </div>

  <div
    class={[
      'heres_slot_action',
      showPanel ? 'heres_visible' : 'heres_display_none',
    ].join(' ')}
  >
    <slot name="action_close" />
  </div>

  {#if counterNotifications && !showPanel}
    <div id="badge_notifications">
      {counterNotifications}
    </div>
  {/if}
</div>

<style>
  #badge_notifications {
    background-color: red;
    color: #fff;
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    position: fixed;
    top: 0px;
    right: 0px;
    border-radius: 50%;
    font-weight: bold;
  }

  .heres_slot_action {
    height: 100%;
    width: 100%;
  }

  :global(.heres_slot_action.heres_hidden) {
    animation: rotate-in-2-cw 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  }

  :global(.heres_slot_action.heres_visible) {
    animation: rotate-out-2-cw 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  }
  @keyframes rotate-in-2-cw {
    0% {
      transform: rotate(-45deg);
      opacity: 1;
    }

    100% {
      transform: rotate(0);
      opacity: 0;
    }
  }

  @keyframes rotate-out-2-cw {
    0% {
      transform: rotate(-45deg);
      opacity: 0;
    }

    100% {
      transform: rotate(0);
      opacity: 1;
    }
  }
</style>
