import isEqual from 'lodash/isEqual';

export class Chat {
  constructor({ agent = null, hiveUrl = null, local = false } = {}) {
    this.hiveUrl = hiveUrl;
    this.agent = agent;
    this.__local = local;
    this.__prevPipeData = null;

    /**
     * Arguments to pass to chat
     * @ignore
     */
    this.args = {
      /**
       * External id of user
       * @ignore
       */
      externalId: '',

      /**
       * Pipe id or data
       */
      pipeify: null,

      refId: null,

      /**
       * Code of step to start
       * @ignore
       */
      step: null,

      /**
       * Id of trigger
       * @ignore
       */
      triggerId: null,

      /**
       * Integrity of user
       * @ignore
       */
      integrity: '',

      /**
       * Platform
       */
      platform: 'web',

      /**
       * Url
       */
      fromUrl: null,
    };
  }

  setup({ agent = null, hiveUrl = null }) {
    this.agent = agent;
    this.hiveUrl = hiveUrl;
  }

  get __baseUrl() {
    if (this.__local) {
      return 'http://localhost:8080/';
    }
    return `https://${this.agent}.client.hereschat.it/`;
  }

  getUrl(channel = 'heres') {
    const cnl = channel === 'heres' ? '' : `${channel.trim().toLowerCase()}/`;
    return `${this.__baseUrl}#/${cnl}embed?${this.queryParams}`;
  }

  async updateArgs({ session, step, fromUrl }, store = null) {
    if (!session.data || typeof session.data !== 'object') {
      session.data = {};
    }
    this.args.fromUrl = fromUrl || window.location.href;
    this.args.integrity =
      session.integrity || (session.data && session.data.integrity);
    this.args.step = step;
    this.args.externalId = session.userId;
    this.args.platform = session.platform;
    if (!this.args.externalId) {
      this.args.externalId = (store && store.read('heres_id')) || 0;
    }

    const data = JSON.parse(JSON.stringify(session.data));
    if (data && data.integrity) {
      delete data.integrity;
    }

    if (!(data instanceof Object)) {
      this.args.pipeify = null;
    } else if (!isEqual(data, this.__prevPipeData)) {
      try {
        const pipeify = await this.uploadPipe(data);
        this.args.pipeify = pipeify;
        // this.__logger.log('Loaded pipe ', pipeify)
      } catch (error) {
        this.args.pipeify = null;
        // this.__logger.log('Error load pipe', error)
      }
    }

    this.__prevPipeData = JSON.parse(JSON.stringify(data));
  }

  get queryParams() {
    const queryParams = [];
    for (const key in this.args) {
      const value = this.args[key];
      if (value) {
        queryParams.push(key + '=' + encodeURIComponent(value));
      }
    }
    return queryParams.join('&');
  }

  async uploadPipe(data) {
    if (typeof data !== 'object' || Object.keys(data).length <= 0) {
      return null;
    }

    const dataify = JSON.stringify(data);
    if (dataify.length > 256) {
      // Upload pipe through hive
      const response = await fetch(this.hiveUrl + '/Pipe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          object: data,
        }),
      });
      const pipe = await response.json();
      return pipe.id;
    }

    // Upload pipe through url
    return dataify;
  }

  sendData(data) {
    try {
      const iframe = document.getElementById('heres_iframe').contentWindow;
      iframe.postMessage(data, '*');
    } catch (error) {}
  }
}
